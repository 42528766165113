.parallax-container {
  width: 100%;
  height: 358px; /* Adjust height as needed */
  overflow: hidden;
  position: relative;
}

.parallax {
  background-image: url("../Assets/imgs/img6.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.parallax-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.parallax-content h1 {
  font-size: 3em; /* Adjust font size as needed */
  margin-bottom: 10px;
}

.parallax-content p {
  font-size: 1.5em; /* Adjust font size as needed */
}
@media (max-width: 992px) {
  .parallax-content h1 {
    font-size: 30px; /* Adjust font size as needed */
  }
  .parallax-content p {
    font-size: 18px; /* Adjust font size as needed */
  }
}
