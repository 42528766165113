.our-product {
  padding: 70px 0 40px;
  background-color: #f5f5fa;
}
.our-product .container .card-body {
  padding: 25px;
  border: none;
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  min-height: 293px;
}
.our-product .container p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  color: #5a5a5a;
  margin-bottom: 20px;
  max-width: 550px;
}
.our-product .container .card {
  border: none;
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  /* background-color: transparent; */
}
.card-img-top {
  border-radius: 0;
  object-fit: cover;
}
.our-product .container .card .card-body .card-title {
  font-size: 1.6rem;
  line-height: 1.2em;
  font-weight: 600;
  font-style: normal;
  color: #1a1a1a;
  margin-bottom: 20px;
}
.our-product .container .card .card-body .card-text {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #454654;
}
.our-product .container .card-grid {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-wrap: wrap;
}

.card-grid .col-lg-4 {
  /* margin: 0 !important; */
  padding: 0 !important;
}

@media (max-width: 769px) {
  .our-product .container .card .card-body .card-title {
    font-size: 22px;
    line-height: 28px;
  }
  .our-product .container .card .card-body .card-text {
    font-size: 14px;
    line-height: 20px;
  }
  .link-readmore {
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen and (max-width: 480px) {
  .our-product {
    padding: 20px 0 20px;
  }
}
