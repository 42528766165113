body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}

.footer-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;
}

.footer-section h3 {
  color: #808080;
  font-size: 36px;
  line-height: 42px;
  font-weight: bold;
}

.footer-section span {
  padding: 5px 0;
  font-size: 16px !important;
  font-weight: normal !important;
  font-style: normal !important;
  color: #fff !important;
  background-color: #808080 !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  text-transform: uppercase;
  width: 150px;
  margin: 0 auto;
  box-shadow: inset -5px -5px 10px rgba(140, 56, 68, 1),
    inset 5px 5px 10px rgba(235, 63, 87, 1);
}
