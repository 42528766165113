* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}
body {
  font-family: "Roboto", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.anchor {
  color: black;
  text-decoration: none;
}
.header {
  width: 100%;
  padding: 15px 0px;
  background-color: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 101;
}
.header-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-wraper .logo img {
  width: 220px;
  height: 54px;
}
.header-wraper .nav-items ul {
  display: flex;
  list-style: none;
  column-gap: 30px;
  align-items: center;
}
.header-wraper .nav-items ul li .nav-item {
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  transition: 0.3s ease-in;
  cursor: pointer;
}
.dropDown {
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  transition: 0.3s ease-in;
  cursor: pointer;
}
.dropDown:hover {
  color: #c1bdbd;
}
.header-wraper .nav-items ul li .nav-item:hover {
  color: #b5b3b3;
}

i {
  color: #fff;
}
i:hover {
  color: #808080;
}
.header-wraper .navlinks {
  display: flex;
  column-gap: 25px;
  align-items: center;
}
.banner-section {
  background-image: url("../Assets/imgs/img6.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  position: relative;
}
.banner-section::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.baner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}
.baner-text .banner-head {
  color: #fff;
  font-size: 53px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 62px;
}
.baner-text .short-text {
  color: #c8c8c8;
  font-size: 23px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}
.call-button {
  display: block;
  color: #fff;
  border: 1px solid #ffff;
  font-size: 18px;
  padding: 12px 25px;
  width: 100%;
  max-width: max-content;
  text-decoration: none;
  margin: auto;
  line-height: 22px;
  cursor: pointer;
  transition: 0.3s linear;
}
.call-button:hover {
  background-color: #808080;
  border: 1px solid transparent;
}
.header.scrolled {
  background-color: #ffff;
}
.header.scrolled .header-wraper .nav-items ul li a {
  color: #000;
}
.header.scrolled .header-wraper .nav-items ul li a:hover {
  color: #808080;
}
.header.scrolled .header-wraper .nav-items ul li .nav-item {
  color: #000;
}
.header.scrolled .header-wraper .nav-items ul li .nav-item:hover {
  color: #808080;
}
.header.scrolled .header-wraper .nav-items ul .dropDown {
  color: #000;
}
.header.scrolled .header-wraper .nav-items ul .dropDown:hover {
  color: #808080;
}

.header.scrolled i {
  color: #000;
}
.header.scrolled i:hover {
  color: #808080;
}
.header.scrolled .menubar svg {
  fill: #000;
}
.dis-desk {
  display: block;
}
.dis-mobile {
  display: none;
}
.menubar svg {
  width: 20px;
  fill: #fff;
}
.cros-icon {
  border: 1px solid #000;
  border-radius: 50%;
  font-size: 14px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subdrop-down {
  color: black !important;
  padding-left: 10px;
}
.subdrop-down .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid black;
  margin-left: 30px;
}
.subdrop-down .dropdown-menu {
  position: absolute !important;
  inset: -33px auto auto 148px !important;
  transform: translate(10px, 25px) !important;
}

@media (max-width: 768px) {
  .dis-mobile {
    display: block !important;
  }
  .dis-desk {
    display: none !important;
  }
  .flexitem {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .header-wraper .logo img {
    width: 130px;
  }
  .header-wraper .nav-items ul {
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
    row-gap: 15px;
    padding: 30px !important;
    align-items: flex-start;
  }
  .header-wraper .nav-items ul li a {
    color: #000;
  }
  .nav-links {
    display: none !important;
  }
  .nav-links.active {
    display: flex !important;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .baner-text {
    width: 100%;
    max-width: 90%;
  }
  .baner-text .banner-head {
    font-size: 24px;
    line-height: 32px;
  }
  .baner-text .short-text {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .banner-section {
    height: 100vh;
  }

  .header-wraper .nav-items ul li .nav-item {
    text-decoration: none;
    font-size: 15px;
    color: #808080;
    font-weight: 400;
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  .dropDown {
    text-decoration: none;
    font-size: 15px;
    color: #808080;
    font-weight: 400;
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  .subdrop-down .dropdown-menu {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(10px, 25px) !important;
  }
}
