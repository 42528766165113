.footerContainer {
  background-color: #181f2a;
  max-width: 100% !important;
}

.footerRow {
  padding: 30px;
}

.foterLogoDesc {
  margin-bottom: 1em;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  color: #cbd2de;
}

.footerHeading {
  color: #ffffff;
  line-height: 1.2em;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}
.FooterLogo {
  width: 200px;
  margin-bottom: 30px;
}
.footerUl {
  list-style-type: none;
  padding: 0% !important;
  margin-top: 20px;
}

.footerUl li {
  padding-bottom: calc(8px / 2);
  display: flex;
  font-size: inherit;
  align-items: var(--icon-vertical-align, center);
  margin-top: 10px;
}

.footerIcon {
  color: #808080;
  cursor: pointer;
  list-style-type: none;
  width: 1.25em;
}
.footerSocialIcon {
  color: #808080;
  cursor: pointer;
  list-style-type: none;
  /* width: 2em; */
  height: 1.25em;
}
.footerSocialIconContainer {
  display: flex;
  width: 16%;
  justify-content: space-between;
}
.footerspan {
  color: #cbd2de;
  padding-left: 16px;
  display: inline-block;
  align-self: center;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  cursor: pointer;
}
.footerspan:hover {
  color: #f9f6f6;
}

.Copyright {
  color: #f9f6f6;
  text-align: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 576px) {
  .footerHeading {
    margin-top: 30px;
  }
  .footerSocialIconContainer {
    width: 60px;
  }
}
