.contactus .container {
  padding: 70px 0;
}

.errMsg {
  color: #808080;
  margin-top: -20px;
  margin-bottom: 10px;
}

.contactus .container .form {
  border-radius: 5px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
}

.contactus .container .form .form-body {
  padding: 62px 20px 30px 42px;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.contactus .container .form .form-body h2 {
  font-size: 36px;
  line-height: 42px;
  font-weight: bold;
  font-style: normal;
  text-transform: capitalize;
  color: #808080;
}

.contactus .container .form .form-body p {
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  font-style: normal;
  color: #67748e;
}

.lable-span {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-style: normal;
  color: #252f40;
  margin-bottom: 5px;
}

.contactus-input {
  width: 100%;
  border: 0;
  padding: 5px 0 5px 10px;
  box-shadow: inset 0px 0px 8px rgba(120, 120, 171, 0.7);
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #7878ab;
  border-radius: 10px;
  outline: none;
  margin-bottom: 24px;
}

.contactContainer {
  width: 80% !important;
  background-color: white !important;
}

.contactText {
  margin-top: 1em;
}

.contactH3 {
  color: #1a1a1a;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  line-height: 1.4;
}

.contactField {
  background-color: #ffffff;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px 0px 0px 0px;
  font-family: "Maven Pro", Sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #000000;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #69727d;
  vertical-align: middle;
  flex-grow: 1;
  padding: 0.75em;
  height: auto;
  margin-top: 15px;
}

.contactFormButton {
  background-color: #696868;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  border-style: solid;
  border-radius: 0px 0px 0px 0px;
  padding: 15px 45px 15px 45px;
  min-height: 40px;
  border: none;
  display: inline-block;
  line-height: 1;
  margin-top: 20px;
}

.contact-info {
  /* background: url(../Assets/Image.webp) top center no-repeat; */
  background-size: cover;
  width: 40%;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-info img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 4;
}

.contact-info-body {
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  padding-left: 80px;
  padding-right: 40px;
}

.contact-info h2 {
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  font-style: normal;
  color: #ffff;
  margin-bottom: 0;
}

.contact-info p {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #ffff;
  margin-bottom: 0;
}

.contact-info p span {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-style: normal;
  color: #ffff;
  margin-bottom: 0;
  margin-left: 20px;
}

.social-link {
  display: flex;
  column-gap: 30px;
}

.social-link p {
  font-size: 24px;
  cursor: pointer;
}

.contactInfoMainDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 20px;
}

.contactIconContainer {
  background-color: rgb(235, 232, 232);
  fill: #808080;
  color: #808080;
  font-size: 20px;
  border-radius: 50%;
  padding: 0.5em;
  width: 50px;
  text-align: center;
  height: 50px;
}

.contactIcon {
  width: 1em;
  height: 1em;
}
.contactsubHeading {
  margin-bottom: 8px;
  color: #1a1a1a;
  font-size: 1.3333333333333rem;
  line-height: 1.2em;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}
.contactsubDesc {
  color: #808080;
}
.contactSubContainer {
  width: 75%;
  padding-left: 10px;
}
/* .customFileInput::-webkit-file-upload-button {
  visibility: hidden;
}

.customFileInput::before {
  content: "Choose File";
} */
@media (max-width: 769px) {
  .contactus .container .form .form-body {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .overlay {
    border-radius: 10px;
  }

  .contact-info {
    width: 100%;
  }

  .social-link {
    margin-bottom: 20px;
  }

  .contactus .container {
    padding: 30px 0;
  }

  .contactus .container .form .form-body {
    padding: 30px;
  }
}
