.about-us {
  padding: 70px 0 0 0;
  position: relative;
  overflow: hidden;
}
.aboutus-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 20px;
}
.main-heading {
  font-size: 40px;
  line-height: 46px;
  font-weight: bold;
  font-style: normal;
  color: #1a1a1a;
  /* text-transform: uppercase; */
  position: relative;
  margin-bottom: 40px;
}
.main-heading:after {
  content: "";
  position: absolute;
  width: 92px;
  height: 4px;
  background-color: #282828;
  bottom: -17px;
  left: 0;
}
.aboutus-col .P-heading {
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  font-style: normal;
  color: #808080;
  border-bottom: none;
}
.aboutus-col .number {
  font-size: 40px;
  line-height: 46px;
  font-weight: bold;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  padding-bottom: 0;
  border: none;
}
.aboutus-col .p-with-border {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  color: #5a5a5a;
  padding-bottom: 30px;
  border-bottom: 1px solid #808080;
  margin-right: 30px;
}
.aboutus-col .p-without-border {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  color: #5a5a5a;
  /* padding-bottom: 30px; */
  /* border-bottom: 1px solid #808080; */
  margin-right: 30px;
}
.about-us-img {
  height: auto;
  width: 100%;
}
.img-div {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
}
.img-div img {
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-us-row {
  margin-bottom: 80px;
}
.img-div::after {
  content: "";
  position: absolute;
  padding: 20px px;
  width: 94%;
  height: 100%;
  bottom: -16px;
  right: -15px;
  border: 4px solid #c7ced9;
  z-index: -1;
}
.img-div2 {
  position: relative;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
}
.img-div2 img {
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-div2::after {
  content: "";
  position: absolute;
  padding: 20px px;
  width: 94%;
  height: 100%;
  bottom: -16px;
  right: -18px;
  border: 4px solid #c7ced9;
  z-index: 0;
}
.col-lg-6 {
  z-index: 1;
  margin-left: 30px;
}

/* .img-div {
  position: absolute;
} */
@media (max-width: 1119px) {
  .main-heading {
    font-size: 30px;
    line-height: 36px;
  }
  .aboutus-col .P-heading {
    font-size: 24px;
    line-height: 30px;
  }
  .aboutus-col p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .img-div {
    position: relative;
  }
  .col-lg-6 {
    margin-left: 5px;
  }
}
